<template>
    <div class="content-wrapper">
        <PageHeader screenName="Ver Pontos de Atendimento" :linkItems="linkItems"/>

        <ConfirmModal 
            itemIdentifier="ponto de atendimento"
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="changeStateItem"
            @canceled="cancelChangeState"
        />

        <TimedAlert variant="danger" :message="alert.errorMessage" :show="alert.show" />

        <main class="card hidden_overflow_tabela">
            <div v-if="canInsert" class="pt-2 px-2">
                <b-button @click="redirectToRegister" variant="primary-button">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"   
                    />
                    <span class="align-middle">Adicionar novo ponto de atendimento</span>
                </b-button>
            </div>
            <hr>
            <TableFilter
                :filter="filter"
                @filterTable="loadDataPontoAtendimento"
            />
            <div v-if="loadingData" class="d-flex justify-content-center my-3">
                <div class="spinner-border text-custom-blue" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <section class="list-ponto-atendimento" v-if="!loadingData">
                <b-table
                    id="tableDoenca"
                    responsive
                    :stickyColumn="true"
                    :busy.sync="filtering"
                    :fields="table.fields"
                    :items="table.items"
                    @sort-changed="ordenarColuna"
                    no-local-sorting
                    :no-sort-reset="true"
                >
                    <template #cell(descricao)="row">
                        <span class="font-weight-bold">{{ row.item.descricao }}</span>
                    </template>
                    <template #cell(nome_cidade)="row">
                        <span class="font-weight-bold">{{ row.item.nome_cidade }}</span>
                    </template>
                    <template #cell(ativo)="row">
                        <TableSwitch 
                            :disabled="!canEdit"
                            :checked="row.item.ativo" 
                            :description="row.item.descricao"
                            :itemId="row.item.id_ponto_atendimento"
                            @stateModified="openModalConfirm"
                        />
                    </template>
                    <template #cell(actions)="row">
                        <feather-icon
                            @click.prevent="redirectToShow(row.item)"
                            id="view-doenca"
                            role="button"
                            class="text-custom-blue mr-1"
                            icon="EyeIcon"
                            size="18"
                            v-b-tooltip.hover.html 
                            title="<b>Detalhes Ponto Atendimento</b>" 
                        />
                        <feather-icon
                            v-if="canEdit"
                            @click.prevent="redirectToEdit(row.item)"
                            id="edit-doenca"
                            role="button"
                            class="text-custom-blue"
                            icon="EditIcon"
                            size="18"
                            v-b-tooltip.hover.html 
                            title="<b>Editar Ponto Atendimento</b>"
                        />
                    </template>
                </b-table>
                <div class="pb-2 px-1">
                    <span v-if="withoutData" class="ml-1">Nenhum ponto de atendimento encontrado</span>
                    <b-col sm="12" class="mt-2">
                        <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage"/>
                    </b-col>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import {actions, subjects} from "@/libs/acl/rules";
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable } from 'bootstrap-vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue';
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import TableSwitch from '@/views/components/custom/table-switch/TableSwitch.vue';
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue';

export default {
    title: 'Pontos de atendimento',

    components: {
        PageHeader, BRow, BCol, BForm, BFormGroup, BFormInput,
        BButton, BTable, TableFilter, CustomPagination,
        TableSwitch, ConfirmModal, TimedAlert
    },

    data() {
        return {
            linkItems: [
                {
                    name: 'Pontos de Atendimento',
                    active: true
                }
            ],
            loadingData: true,
            filtering: false,
            withoutData: false, 
            filter: false,
            canEdit: false,
            canInsert: false,
            table: {
                fields: [
                    {key: 'descricao', label: 'Pontos de atendimento', sortable: true},
                    {key: 'nome_cidade', label: 'Cidade', sortable: true, class: 'mw-300'},
                    {key: 'ativo', label: 'Status', sortable: true},
                    {key: 'actions', label: 'Ações', sortable: false, class: 'mw-150 text-center col-1'}
                ],
                items: [],
                columnOrder: 'descricao',
                order: 'asc'
            },
            paginationData: {
                currentPage: 0,
                totalLines: 0,
                fromLine: 0,
                toLine: 0,
                defaultSize: 10
            },
            activeOrInactiveItem: null,
            modal: {
                showModal: false,
                typeModal: 'success',
            },
            alert: {
                errorMessage: '',
                show: false
            },
        }
    },
    mounted() {
        this.canEdit = this.verifyPermissions(actions.ATUALIZAR)
        this.canInsert = this.verifyPermissions(actions.INSERIR)
    },
    methods: {
        loadDataPontoAtendimento(filterData = null) {
            this.paginationData.defaultSize = filterData ? filterData.defaultSize : 10;

            if(filterData.hasOwnProperty('currentPage')) { this.paginationData.currentPage = filterData.currentPage; }

            const parameters = {
                termoPesquisa: filterData ? filterData.searchTerm : '',
                tamanho: this.paginationData.defaultSize,
                pagina: this.paginationData.currentPage,
                colunaOrdenar: this.table.columnOrder,
                ordem: this.table.order
            };

            this.filtering = true;

            this.$http.get(this.$api.listOrSavePontoAtendimento(), { params: parameters }).then(({ data }) => {
                this.table.items = data.data;

                this.withoutData = false;

                if(data.data.length == 0) {
                    this.withoutData = true;
                }

                this.loadingData = false;
                this.filtering = false;

                this.startPagination(data);
            })
        },

        startPagination(data) {
            this.paginationData.currentPage = data.current_page;
            this.paginationData.totalLines = data.total;
            this.paginationData.fromLine = data.from;
            this.paginationData.toLine = data.to;
        },

        ordenarColuna(sort) {
            this.table.columnOrder = sort.sortBy;
            this.table.order = sort.sortDesc ? 'desc' : 'asc';

            this.filtering = true;
            this.filter = !this.filter;
        },

        updateCurrentPage(page) {
            this.paginationData.currentPage = page;

            this.filtering = true;
            this.filter = !this.filter;
        },

        openModalConfirm(values) {
            this.modal.typeModal = 'warning';

            if(values.state) {
                this.modal.typeModal = 'success';
            }

            this.$helpers.changeStateActiveTableRow(this.table.items, values, 'id_ponto_atendimento');

            this.activeOrInactiveItem = values;
            this.modal.showModal = true;
        },

        changeStateItem() {
            this.$http.patch(this.$api.pontoAtendimentoParameter(this.activeOrInactiveItem.itemId)).then(() => {
                this.modal.showModal = false;
            }).catch(({ response: { data } }) => {
                this.modal.showModal = false;

                this.$helpers.backStateActiveTableRow(this.table.items, this.activeOrInactiveItem, 'id_ponto_atendimento');

                this.alert.errorMessage = data.errors;
                this.alert.show = !this.alert.show;
            })
        },

        cancelChangeState() {
            this.$helpers.backStateActiveTableRow(this.table.items, this.activeOrInactiveItem, 'id_ponto_atendimento');

            this.modal.showModal = false;
        },

        redirectToRegister() {
            this.$router.push({name: 'ponto-atendimento-register'});
        },

        redirectToShow({ id_ponto_atendimento, descricao }) {
            this.$router.push(
                { 
                    name: 'ponto-atendimento-view', 
                    params: {
                        id_ponto_atendimento,
                        descricao
                    }
                }
            );
        },

        redirectToEdit({ id_ponto_atendimento, descricao }) {
            this.$router.push(
                { 
                    name: 'ponto-atendimento-edit', 
                    params: {
                        id_ponto_atendimento,
                        descricao
                    }
                }
            );
        },
        
        verifyPermissions(action) {
            const permission = this.$can(actions[action], subjects.PONTO_ATENDIMENTO)

            return [permission].includes(true);
        }
    }
}
</script>

<style>
.hidden_overflow_tabela{
    overflow: hidden;
}
</style>